
// the old header without perf improvemetns
require([
    'modals',
    'ch_menu',
    'ch_semantic_wishlist',
    'ch_site_notification',
    'ch_footer',
    'ch_welcome_overlay',
    'ch_semantic_search',
], function (modals) {
    /**
     * Set the main category button that is active
     * and make sure it is in view (for mobile users)
     *
     */
    function setCategoryMenuItemActive(mainCategoryId) {
        const mainMenu = document.getElementById('main-menu');
        let activeMenuItem;

        if (mainMenu) {
            if (mainCategoryId) {
                const activeLink = mainMenu.querySelector(`[data-category-id="${mainCategoryId}"]`);
                activeMenuItem = activeLink ? activeLink.parentElement : undefined;
            } else {
                // determine the base uri path, excluding sub/tertiary categories
                // regex splits "/nyc/classes/dance/salsa/dance-like-emilio" to:
                // [1] = "/nyc/classes/dance"
                // [2] = "/salsa"
                // [3] = "/dance-like-emilio"
                var uriSplit = window.location.pathname.split(/^(\/{0,1}[\w\d\-_]*\/{0,1}[\w\d\-_]*\/{0,1}[\w\d\-_]*)(\/{0,1}[\w\d\-_]*)/);

                if (
                    mainMenu &&
                    uriSplit.length >= 2
                ) {
                    const activeLink = mainMenu.querySelector(`.ch-menu-dropdown-button[href="${uriSplit[1]}"]`);
                    activeMenuItem = activeLink ? activeLink.parentElement : undefined;
                }
            }

            // set the selected category menu item as "active"
            // we do this here (instead of in the markup) because the menu markup is cached
            if (activeMenuItem) {
                activeMenuItem.classList.add('active');
                // ios safari does will scroll both x,y "into view" so we can't use this:
                // $activeMenuItem[0].scrollIntoView
                if (CH.isMobile) {
                    activeMenuItem.scrollIntoView();
                }
            }
        }
    }

    // some tracking I copied over from ch_ga
    const giftCardSubnav = document.querySelector('.js-gift-card-subnav');
    giftCardSubnav && giftCardSubnav.addEventListener('click', function () {
        ga('send', 'event', 'Gift Card Ads', 'Subcategory Clicked', null, {
            nonInteraction: false,
        });
    });

    const navBar = document.getElementById('NavBar');
    navBar && navBar.addEventListener('click',  function(e) {
        if (e.target.classList.contains('item')) {
            ga(
                'send',
                'event',
                'Header Tracking',
                'Clicked',
                e.innerText,
                { nonInteraction: false }
            );
        }
    });

    setCategoryMenuItemActive(CH.activeMainCategoryId);

    modals.initUserAuthModal();
});

define("ch_header", function(){});

