/**
 * Create a Stripe object that can process payments for us.
 */
define('ch_stripe',[],function () {
    gateway = function ({
            apiKey,
            checkoutType = 'checkout',
            checkoutFormId = '',
            returnUrl = '',
            initialAmount = 150
            } = {}
        ) {
        this.stripeInstance = window.Stripe(apiKey);
        this.checkoutType = checkoutType;
        this.checkoutFormId = checkoutFormId;
        this.returnUrl = returnUrl;

        this.elements = this.stripeInstance.elements({
            mode: 'payment',
            currency: 'usd',
            amount: Math.round(initialAmount * 100),
            paymentMethodTypes: ['card', 'link'],
            setup_future_usage: 'off_session',
            appearance: {
                variables: {
                    borderRadius: '4px',
                },
            },
        });
    }

    /**
     * Setter: set the checkout form ID.
     */
    gateway.prototype.setCheckoutFormId = function (checkoutFormId) {
        this.checkoutFormId = checkoutFormId;
    }

    /**
     * Setter: set the checkout type.
     */
    gateway.prototype.setCheckoutType = function (checkoutType) {
        this.checkoutType = checkoutType;
    }

    /**
     * Setter: set the return URL.
     */
    gateway.prototype.setReturnUrl = function (returnUrl) {
        this.returnUrl = returnUrl;
    }

    /**
     * Add an express checkout element to the provided selector.
     */
    gateway.prototype.addExpressElement = function (mountId) {
        $expressCheckoutSection = $('.js-express-checkout-section');

        this.expressCheckoutElement = this.elements.create("expressCheckout", {
            paymentMethodOrder: ['apple_pay', 'google_pay', 'link'],
            buttonHeight: 42,
            layout: {
                overflow: 'never',
            },
        });
        this.expressCheckoutElement.mount(mountId);
        this.expressCheckoutElement.on('ready', (e) => {
            if (!e.hasOwnProperty('availablePaymentMethods')) {
                $expressCheckoutSection.attr('hidden', '');
            }

            window.setTimeout(() => {
                $(mountId).removeClass('ui placeholder');
                $(mountId).addClass('stripe-ready');
            }, 350)
        });

        // Update payment intent first before proceeding to express payment.
        this.expressCheckoutElement.on('click', async (event) => {
            const validateResponse = await this.validatePayment(true);
            console.log(validateResponse)
            if (validateResponse.success) {
                // If update succeeded, present express checkout option.
                const options = {
                  emailRequired: true
                };
                event.resolve(options);
            }
            else {

                // Gift card page uses native HTML5 client-side validation
                // This triggers those validation errors on the fields
                $checkoutForm = $(this.checkoutFormId);
                if (this.checkoutType === "gift-card" && $checkoutForm.length) {
                    $checkoutForm[0].reportValidity();
                }
                
                // If update failed, provide error information.
                $('.js-credit-card-errors').remove();
                $('<div/>')
                    .addClass('ui message red js-credit-card-errors')
                    .html(validateResponse.message)
                    .prependTo($(mountId));
            }
        });

        this.expressCheckoutElement.on('confirm', async (event) => {
            const confirmResponse = await this.confirmPayment(true);
            if (!confirmResponse.success) {
                alert(confirmResponse.message);
                return false;
            }
        });
    }

    /**
     * Add a standard (e.g. credit card) checkout element to the provided selector.
     */
    gateway.prototype.addStandardPaymentElement = function (mountId) {
        stripePaymentElement = this.elements.create('payment', {
            terms: {
                card: 'never'
            }
        });
        stripePaymentElement.mount(mountId);
    }

    /**
     * Process a standard (not express) payment.
     */
    gateway.prototype.processStandardPayment = async function () {
        $checkoutForm = $('#form-checkout-semantic');
        $creditCardIdField = $(':input[name=credit_card_id]', $checkoutForm);
        $paymentMethodField = $(':input[name=payment_method]', $checkoutForm);

        const isUsingExistingPaymentMethod = $creditCardIdField.val() > 0 && $paymentMethodField.filter(':checked').val() == 'existing';


        // First, validate the checkout form.
        const validateResponse = await this.validatePayment(false);
        if (!validateResponse.success) {
            return validateResponse;
        }

        // Next, if not using an existing card, validate payment details.
        if (!isUsingExistingPaymentMethod) {
            const { error: submitError } = await this.elements.submit();
            if (submitError) {
                console.error(submitError);

                let message = 'Invalid payment details';
                if ('message' in submitError && submitError.message) {
                    message = submitError.message;
                }

                return {
                    success: false,
                    message: message
                };
            }
        }

        const confirmResponse = this.confirmPayment(false, isUsingExistingPaymentMethod);
        return confirmResponse;
    }

    /**
     * Validate the payment form.
     */
    gateway.prototype.validatePayment = async function (isExpress = false) {
        let validateUrl = '/course/' + this.checkoutType + '/validate-form';
        if (isExpress) {
            validateUrl = validateUrl + '?is_express=1';
        }
        const validateResponse = await fetch(validateUrl, {
                method: 'POST',
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                body: new URLSearchParams(new FormData($(this.checkoutFormId)[0])),
            });
        const res = await validateResponse.json();
        return res;
    }

    /**
     * Create and confirm a Stripe Payment Intent.
     */
    gateway.prototype.confirmPayment = async function (isExpress = false, isUsingExistingPaymentMethod = false) {
        let createPaymentIntentUrl = '/course/' + this.checkoutType + '/create-payment-intent';
        if (isExpress) {
            createPaymentIntentUrl = createPaymentIntentUrl + '?is_express=1';
            if (this.checkoutFormId != '#form-checkout-semantic') {
                createPaymentIntentUrl = createPaymentIntentUrl + '&is_super_express=1';
            }
        }

        // First, create a new payment intent for this checkout.
        const updateResponse = await fetch(createPaymentIntentUrl, {
            method: 'POST',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            body: new URLSearchParams(new FormData($(this.checkoutFormId)[0])),
        });
        res = await updateResponse.json()
        if (res.success) {
            clientSecret = res.client_secret;
        }
        else {
            // Should not fail at this stage, but just in case, report it.
            return {
                success: false,
                message: 'Sorry, there was a problem preparing your payment.  Please reload the page and try again.'
            }
        }

        let returnUrl = this.returnUrl;
        if (isExpress) {
            returnUrl = returnUrl + '?is_express=1';
        }

        // Confirm the payment with Stripe.  If this step succeeds, the
        // customer will be redirected to the returnUrl.  So, we only have to
        // handle the error case below.
        const {error} = await this.stripeInstance.confirmPayment({
            elements: isUsingExistingPaymentMethod ? undefined : this.elements,
            clientSecret,
            confirmParams: {
                return_url: returnUrl,
            },
        });
        if (error) {
            console.log(error);
            // This point is reached only if there's an immediate error when confirming the payment. Show the error to your customer (for example, payment details incomplete).
            return {
                success: false,
                message: error.message
            }
        }

        // This code should never be called, because the customer should be
        // redirected to the complete page by this point.  But, just in case
        // something unexpected goes wrong, let's throw one final error here.
        return {
            success: false,
            message: 'Sorry, there was a problem completing your payment.  Please reload the page and try again.'
        }
    }

    /**
     * Update the amount in Stripe Elements.
     */
    gateway.prototype.updateAmount = function (amount) {
        this.elements.update({ amount: Math.round(amount * 100) });
    }

    return {
        gateway
    };
});

